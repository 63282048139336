import {
  Box,
  Button,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import strings from "../utilities/strings";
import PlayStoreImg from "../images/play-store.webp";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import constants from "../utilities/constants";
import { GitHub } from "@mui/icons-material";
import itemProjectData from "../utilities/itemProjectData";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

function Project() {
  const isWideScreen = useMediaQuery("(min-width: 768px)");
  const cols = isWideScreen ? 2 : 1;

  let lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en";

  const publishedAppBtn = (icon, label, url) => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => window.open(url, "_blank")}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          {icon}
          <Typography variant="h6" color="text.main">
            {label}
          </Typography>
        </Box>
      </Button>
    );
  };

  return (
    <Box p="16px">
      <Box display="flex" flexDirection="column" width="90vw">
        <Typography variant="h6" color="text.secondary">
          {strings.publishedApps}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: "text.secondary" }} />
        <Grid2 container gap={1} justifyContent="center" mt="16px">
          {publishedAppBtn(
            <img
              src={PlayStoreImg}
              alt="Google Play Store Url"
              width="32px"
              loading="lazy"
            />,
            "Google Play",
            constants.socialMediaUrl.playStoreUrl
          )}
          {publishedAppBtn(
            <GitHub fontSize="large" />,
            "GitHub Repository",
            constants.socialMediaUrl.gitHubUrl
          )}
        </Grid2>
        <Typography variant="h6" color="text.secondary" mt="32px">
          {strings.tabsTitleProjects}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: "text.secondary" }} />
        <Box justifyContent="center" alignItems="center" display="flex">
          <ImageList
            sx={{
              width: "100%",
              border: "1px solid #CABFAB",
              borderRadius: "16px",
              p: "8px",
            }}
            cols={cols}
            rowHeight="auto"
            variant="masonry"
          >
            <PhotoProvider speed={() => 500}>
              {itemProjectData.map((item, index) => (
                <ImageListItem
                  key={index}
                  sx={{
                    alignItems: "center",
                    backgroundColor: "secondary.secondary",
                    p: "8px",
                    borderRadius: "16px",
                    color: "text.secondary",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(0.975)",
                    },
                  }}
                >
                  <div className="foo">
                    <PhotoView key={index} src={item.img}>
                      <img
                        style={{
                          borderRadius: "8px",
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </PhotoView>
                  </div>
                  <ImageListItemBar
                    title={item.title}
                    subtitle={
                      <Box
                        display="flex"
                        flexDirection="column"
                        style={{ whiteSpace: "normal" }}
                      >
                        {lang === "in" ? item.descId : lang === "jp" ? item.descJp : item.descEn}
                        <Box display="flex" flexDirection="row" gap={2}>
                          {item.publishedUrl !== "" ? (
                            <IconButton
                              sx={{ width: "32px" }}
                              onClick={() => {
                                window.open(item.publishedUrl, "_blank");
                              }}
                            >
                              <img
                                src={PlayStoreImg}
                                alt="visit play store"
                                width="24px"
                              />
                            </IconButton>
                          ) : null}
                          {item.gitHubUrl !== "" ? (
                            <IconButton
                              sx={{ width: "32px" }}
                              onClick={() => {
                                window.open(item.gitHubUrl, "_blank");
                              }}
                            >
                              <GitHub fontSize="large" color="secondary" />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    }
                    position="below"
                    sx={{ p: "8px" }}
                  />
                </ImageListItem>
              ))}
            </PhotoProvider>
          </ImageList>
        </Box>
      </Box>
    </Box>
  );
}

export default Project;
