import { Box, Typography } from "@mui/material";
import utils from "./utils";
import constants from "./constants";

let lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "en";

let strings = {
  introduction: "Hello, it's me",
  myName: "Ridhwan.",
  bio: "Android Developer",
  tabsTitleAboutMe: "About Me",
  tabsTitleSkills: "Skills",
  tabsTitleProjects: "Projects",
  tabsTitleServices: "Services",
  tabsTitleContactMe: "Contact Me",
  aboutMe: (variant, color, padding) => {
    const age = utils.calculateAge(constants.birthDay);
    const androidExperience = utils.calculateAge(constants.startAndroid);

    return (
      <Box p={padding}>
        <Box display="flex" flexDirection="column" width="90vw">
          <Typography color="secondary" fontWeight="bold" textAlign="center">
            Ridhwan Anwar Fauzan | {age.yearsOnly} years old | Indonesia
            <br />
            <a href={constants.certUrl} target="_blank" rel="noopener noreferrer"><Typography fontSize="12px" textAlign="center" color="text.link">View All Certificates</Typography></a>
          </Typography>
          <Typography
            variant={variant}
            color={color}
          >
            <br />
            I am an Android developer with {androidExperience.age} years of experience, graduated from the <a href={constants.unikomUrl} target="_blank" rel="noopener noreferrer"><Typography color="text.link">Indonesian Computer University</Typography></a> in 2023. I am deeply passionate about technology and dedicated to delivering innovative solutions. I continuously stay up to date with the latest technological advancements by learning from various sources, including the official Android Developer website and others. I have earned certifications from prestigious organizations such as the National Professional Certification Board (BNSP) Indonesia and Dicoding, further validating my expertise in Android development. In addition, I participated in the Bangkit Academy program—a collaborative initiative by Google, GoTo, and Traveloka—where I focused on software development, particularly Android applications, alongside the development of key soft skills.
            <br />
            <br />
            Throughout my career, I have been actively involved in the development of Android and web applications, a few of which have been published on the Google Play Store and GitHub repository under my personal account. In my professional experience, I have made significant contributions to Android development projects within the company, where I was responsible for developing, maintaining, and optimizing applications to meet business objectives.
            <br />
            <br />
            I am a native speaker of Indonesian, proficient in English, particularly for presenting projects and engaging in conversations, and I possess a basic understanding of Japanese. My preferred Android development environment includes native development using Kotlin, Jetpack Compose, XML, the MVVM architecture, Android Lifecycle, Room, Socket, API Integration, and the Clean Code Architecture to ensure maintainable and readable code. I am seeking a challenging and rewarding opportunity as an Android developer, where I can apply my skills and experience to create innovative and impactful applications.
            <br />
            <br />
            Outside of my professional work, I have been a dedicated Calisthenics practitioner and served as a Calisthenics coach from 2021 to 2023. Additionally, I operated my own part-time business, providing repair services for computers, laptops, and phones from 2017 to 2022.
          </Typography>
        </Box>
      </Box>
    );
  },
  skillLevel: ["Beginner", "Intermediate", "Advanced", "Expert"],
  programmingLanguages: "Programming Languages",
  languages: "Languages",
  level: "Level",
  yourName: "Your Name",
  yourEmail: "Your Email",
  yourMessage: "Your Message",
  send: "Send",
  clear: "Clear",
  name: "Name",
  email: "Email",
  message: "Message",
  subject: "Subject",
  publishedApps: "Published Apps",
  curriculumVitae: "Curriculum Vitae (En)",
  lastUpdated: "last updated",
  date: (value) => {
    return utils.formatDate(value, "en-US")
  },
  whatsApp: "WhatsApp",
  email: "Email",
  copyright: "Copyright © 2024 ridhwanafzn All Rights reserved."
};

if (lang === "in") {
  strings = {
    introduction: "Halo, ini saya",
    myName: "Ridhwan.",
    bio: "Android Developer",
    tabsTitleAboutMe: "Tentang Saya",
    tabsTitleSkills: "Kemampuan",
    tabsTitleProjects: "Projek",
    tabsTitleServices: "Layanan",
    tabsTitleContactMe: "Kontak Saya",
    aboutMe: (variant, color, padding) => {
      const age = utils.calculateAge(constants.birthDay);
      const androidExperience = utils.calculateAge(constants.startAndroid);
      return (
        <Box p={padding}>
          <Box display="flex" flexDirection="column" width="90vw">
            <Typography color="secondary" fontWeight="bold" textAlign="center">
              Ridhwan Anwar Fauzan | {age.yearsOnly} Tahun | Indonesia
              <br />
              <a href={constants.certUrl} target="_blank" rel="noopener noreferrer"><Typography fontSize="12px" textAlign="center" color="text.link">Lihat Semua Sertifikat</Typography></a>
            </Typography>
            <Typography
              variant={variant}
              color={color}
            >
              <br />
              Saya adalah seorang pengembang Android dengan pengalaman {androidExperience.age} tahun, lulus dari <a href={constants.unikomUrl} target="_blank" rel="noopener noreferrer"><Typography color="text.link">Universitas Komputer Indonesia</Typography></a> pada tahun 2023. Saya sangat antusias terhadap teknologi dan berdedikasi untuk memberikan solusi inovatif. Saya secara terus-menerus mengikuti perkembangan teknologi terbaru dengan belajar dari berbagai sumber, termasuk situs resmi Android Developer dan lainnya. Saya juga telah memperoleh sertifikasi dari organisasi terkemuka seperti Badan Nasional Sertifikasi Profesi (BNSP) Indonesia dan Dicoding, yang semakin memperkuat keahlian saya dalam pengembangan Android. Selain itu, saya berpartisipasi dalam program Bangkit Academy—sebuah inisiatif kolaboratif dari Google, GoTo, dan Traveloka—di mana saya berfokus pada pengembangan perangkat lunak, khususnya aplikasi Android, serta pengembangan soft skill yang penting.
              <br />
              <br />
              Sepanjang karier saya, saya telah aktif terlibat dalam pengembangan aplikasi Android dan web, beberapa di antaranya telah dipublikasikan di Google Play Store dan repository GitHub menggunakan akun pribadi saya. Dalam pengalaman profesional saya, saya telah memberikan kontribusi signifikan pada proyek pengembangan Android di perusahaan, di mana saya bertanggung jawab untuk mengembangkan, memelihara, dan mengoptimalkan aplikasi guna mencapai tujuan bisnis.
              <br />
              <br />
              Saya adalah penutur asli Bahasa Indonesia, lancar berbahasa Inggris terutama dalam mempresentasikan proyek dan berkomunikasi, serta memiliki pemahaman dasar tentang Bahasa Jepang. Lingkungan pengembangan Android yang saya sukai meliputi pengembangan native menggunakan Kotlin, Jetpack Compose, XML, arsitektur MVVM, Android Lifecycle, Room, Socket, API Integration, dan Clean Code Architecture untuk memastikan kode yang mudah dipelihara dan terbaca. Saya sedang mencari kesempatan yang menantang dan memberi penghargaan sebagai pengembang Android, di mana saya dapat menerapkan keterampilan dan pengalaman saya untuk menciptakan aplikasi yang inovatif dan berdampak.
              <br />
              <br />
              Di luar pekerjaan profesional saya, saya telah menjadi praktisi Calisthenics yang berdedikasi dan pernah menjadi pelatih Calisthenics dari tahun 2021 hingga 2023. Selain itu, saya menjalankan usaha paruh waktu sendiri, menyediakan layanan perbaikan komputer, laptop, dan ponsel dari tahun 2017 hingga 2022.
            </Typography>
          </Box>
        </Box>
      );
    },
    skillLevel: ["Pemula", "Menengah", "Lanjutan", "Ahli"],
    programmingLanguages: "Bahasa Pemrograman",
    languages: "Bahasa",
    level: "Tingkat",
    yourName: "Nama Anda",
    yourEmail: "Email Anda",
    yourMessage: "Pesan Anda",
    send: "Kirim",
    clear: "Bersihkan",
    name: "Nama",
    email: "Email",
    message: "Pesan",
    subject: "Subjek",
    publishedApps: "Aplikasi Terpublikasi",
    curriculumVitae: "Daftar Riwayat Hidup (En)",
    lastUpdated: "terakhir diperbarui",
    date: (value) => {
      return utils.formatDate(value, "id-ID")
    },
    whatsApp: "WhatsApp",
    email: "Email",
    copyright: "Hak Cipta © 2024 ridhwanafzn. Seluruh hak cipta dilindungi."
  };
} else if (lang == "jp") {
  strings = {
    introduction: "こんにちは、私です",
    myName: "リドワン",
    bio: "Android開発者",
    tabsTitleAboutMe: "私について",
    tabsTitleSkills: "スキル",
    tabsTitleProjects: "プロジェクト",
    tabsTitleServices: "サービス",
    tabsTitleContactMe: "お問い合わせ",
    aboutMe: (variant, color, padding) => {
      const age = utils.calculateAge(constants.birthDay);
      const androidExperience = utils.calculateAge(constants.startAndroid);
      return (
        <Box p={padding}>
          <Box display="flex" flexDirection="column" width="90vw">
            <Typography color="secondary" fontWeight="bold" textAlign="center">
              リドワン・アンワル・ファウザン | {age.yearsOnly}歳 | インドネシア
              <br />
              <a href={constants.certUrl} target="_blank" rel="noopener noreferrer"><Typography fontSize="12px" textAlign="center" color="text.link">すべての証明書を見る</Typography></a>
            </Typography>
            <Typography
              variant={variant}
              color={color}
            >
              <br />
              私は{androidExperience.age}年の経験を持つAndroid開発者であり、2023年に<a href={constants.unikomUrl} target="_blank" rel="noopener noreferrer"><Typography color="text.link">インドネシアコンピュータ大学</Typography></a>を卒業しました。技術に対する情熱があり、革新的なソリューションを提供することに専念しています。公式のAndroid Developerサイトやその他のリソースから学ぶことで、最新の技術動向を常にアップデートしています。また、私は国家職業資格委員会（BNSP）インドネシアやDicodingなど、著名な組織から認定を取得しており、Android開発の専門知識がさらに裏付けられています。さらに、Google、GoTo、Travelokaの共同プログラムであるBangkit Academyに参加し、主にAndroidアプリケーションの開発とソフトスキルの向上に注力しました。
              <br />
              <br />
              キャリアを通じて、私はAndroidおよびウェブアプリケーションの開発に積極的に携わっており、そのうちいくつかは個人アカウントでGoogle Play StoreおよびGitHubリポジトリに公開されています。プロフェッショナルな経験においては、会社内のAndroid開発プロジェクトに大きく貢献し、ビジネス目標を達成するためのアプリケーションの開発、保守、最適化を担当しました。
              <br />
              <br />
              私はインドネシア語を母国語とし、プロジェクトのプレゼンテーションや会話において英語を堪能に使いこなし、また、日本語の基礎的な理解も持っています。私が好むAndroid開発環境には、Kotlin、Jetpack Compose、XML、MVVMアーキテクチャ、Android Lifecycle、Room、Socket、APIインテグレーション、そして保守性の高いコードを確保するためのClean Codeアーキテクチャが含まれます。私は、スキルと経験を活かして革新的で影響力のあるアプリケーションを構築できる、挑戦的でやりがいのあるAndroid開発者の機会を求めています。
              <br />
              <br />
              プロフェッショナルな仕事の外では、私はカリステニクスの実践者であり、2021年から2023年までカリステニクスのコーチを務めました。また、2017年から2022年まで、コンピュータ、ノートパソコン、携帯電話の修理サービスを提供する自身のパートタイムビジネスを運営していました。
            </Typography>
          </Box>
        </Box>
      );
    },
    skillLevel: ["初心者", "中級者", "上級者", "専門家"],
    programmingLanguages: "プログラミング言語",
    languages: "言語",
    level: "レベル",
    yourName: "あなたの名前",
    yourEmail: "あなたのメール",
    yourMessage: "あなたのメッセージ",
    send: "送信",
    clear: "クリア",
    name: "名前",
    email: "メール",
    message: "メッセージ",
    subject: "件名",
    publishedApps: "公開されたアプリ",
    curriculumVitae: "履歴書 (英語)",
    lastUpdated: "最終更新日",
    date: (value) => {
      return utils.formatDate(value, "ja-JP")
    },
    whatsApp: "WhatsApp",
    email: "メール",
    copyright: "著作権 © 2024 ridhwanafzn. 全著作権所有。"
  };
}

export default strings;
