const constants = {
  phoneNumber: "6289637504050",
  email: "ridhwanaf8111@gmail.com",
  socialMediaUrl: {
    playStoreUrl:
      "https://play.google.com/store/apps/dev?id=4651864067675716886",
    instagramUrl: "https://www.instagram.com/ridhwanafzn",
    facebookUrl: "https://www.facebook.com/ridhwanafzn",
    linkedInUrl: "https://www.linkedin.com/in/ridhwan-anwar-fauzan-4075b216a/",
    gitHubUrl: "https://github.com/RidhwanAF",
    xUrl: "https://x.com/Ridhwanafzn",
    whatsAppUrl: "https://wa.me/6289637504050",
  },
  birthDay: "2001-11-08",
  startAndroid: "2022-02-01",
  unikomUrl: "https://www.unikom.ac.id/",
  certUrl: "https://drive.google.com/drive/folders/1xySSSJluH6D5ek750woOZ1eYJcKBS_et?usp=sharing",
  skillLevelValue: {
    android: 95,
    jetpackCompose: 95,
    reactJs: 65,
    gitHub: 95,
    figma: 85,
    nodeJs: 40,
    deviceRepair: 72,
    kotlin: 90,
    javaScript: 50,
    typeScript: 50,
    java: 25,
    indonesian: 99,
    english: 75,
    japanese: 25,
  },
  cvUpdateDate: "29/09/2024" // Note: DD/MM/YYYY
};

export default constants;
