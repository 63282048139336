import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import {
  Box,
  NativeSelect,
} from "@mui/material";
import LogoCircle from "../images/logo-circle.png";
import { PhotoProvider, PhotoView } from "react-photo-view";

function ResponsiveAppBar() {
  const [language, setLanguage] = React.useState(
    localStorage.getItem("language") || "en"
  );

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    if (language) {
      setLanguage(language);
      localStorage.setItem("language", language);
      window.location.reload();
    }
  };

  const [elevation, setElevation] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setElevation(scrollTop > 50 ? 2 : 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <AppBar position="sticky" elevation={elevation}>
      <Container maxWidth="xl">
        <Toolbar >
          <Box display="flex" alignItems="center">
            <PhotoProvider speed={() => 500} maskOpacity={0.9}>
              <div className="foo">
                <PhotoView key={LogoCircle} src={LogoCircle}>
                  <img src={LogoCircle} alt="Logo" width="32px" />
                </PhotoView>
              </div>
            </PhotoProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <NativeSelect
              value={language}
              inputProps={{
                name: 'Language',
                id: 'uncontrolled-native',
              }}
              variant="outlined"
              sx={{
                '& .MuiNativeSelect-select': {
                  color: 'text.secondary',
                },
                '& .MuiNativeSelect-icon': {
                  color: 'text.secondary',
                },
              }}
              onChange={handleLanguageChange}
            >
              <option value="en">🇺🇸 English</option>
              <option value="in">🇮🇩 Indonesia</option>
              <option value="jp">🇯🇵 日本語</option>
            </NativeSelect>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
