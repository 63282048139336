import RogPhone3 from "../images/rogphone3.jpg";
import Pixel4 from "../images/pixel4.jpg";
import SamsungS20Fe from "../images/samsung-s20fe.jpg";
import Realme8 from "../images/realme8.jpg";
import ROGGL553VD from "../images/rog-gl553vd.jpg";
import AsusZenfone5Z from "../images/asus-zenfone5z.jpg";

const itemServiceData = [
  {
    id: 1,
    img: RogPhone3,
    title: "Asus ROG Phone 3",
    descEn: "Charging port repair, battery replacement, and thermal paste replacement.",
    descId: "Perbaikan port pengisian daya, penggantian baterai, dan penggantian pasta termal.",
    descJp: "充電ポートの修理、バッテリー交換、サーマルペーストの交換。",
  },
  {
    id: 2,
    img: Pixel4,
    title: "Google Pixel 4",
    descEn: "Battery and screen replacement.",
    descId: "Penggantian baterai dan layar.",
    descJp: "バッテリーと画面の交換。",
  },
  {
    id: 3,
    img: SamsungS20Fe,
    title: "Samsung S20 FE",
    descEn: "Water damage repair.",
    descId: "Perbaikan kerusakan akibat air.",
    descJp: "水損傷の修理。",
  },
  {
    id: 4,
    img: Realme8,
    title: "Realme 8 4G",
    descEn: "Water damage repair and screen replacement.",
    descId: "Perbaikan kerusakan akibat air dan penggantian layar.",
    descJp: "水損傷の修理と画面の交換。",
  },
  {
    id: 5,
    img: ROGGL553VD,
    title: "Asus ROG GL553VD",
    descEn: "RAM upgrade and thermal paste replacement.",
    descId: "Peningkatan RAM dan penggantian pasta termal.",
    descJp: "RAMのアップグレードとサーマルペーストの交換。",
  },
  {
    id: 6,
    img: AsusZenfone5Z,
    title: "Asus Zenfone 5Z",
    descEn: "Fingerprint sensor replacement.",
    descId: "Penggantian sensor sidik jari.",
    descJp: "指紋センサーの交換。",
  },
];

export default itemServiceData;
