import { Android, Devices, GitHub, Javascript } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { keyframes } from '@mui/system';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import strings from "../utilities/strings";
import FigmaImg from "../images/figma.png";
import ReactImg from "../images/react.png";
import NodeJsImg from "../images/nodejs.png";
import KotlinImg from "../images/kotlin.png";
import TypeScriptImg from "../images/typescript.png";
import JetpackComposeImg from "../images/jetpack-compose.png";
import JavaImg from "../images/java.png";
import constants from "../utilities/constants";
import { useEffect, useState } from "react";

function Skills() {

  const shimmer = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

  const skillLevelLabel = (skillValue) => {
    if (skillValue <= 25) {
      return strings.skillLevel[0];
    } else if (skillValue <= 50) {
      return strings.skillLevel[1];
    } else if (skillValue <= 75) {
      return strings.skillLevel[2];
    } else if (skillValue <= 100) {
      return strings.skillLevel[3];
    } else {
      return strings.skillLevel[0];
    }
  }

  const SkillLevel = (icon, label, value, valueLabel) => {
    const thickness = 3;
    const size = 90;

    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const timer = setTimeout(() => {
        setProgress(value);
      }, 300);

      return () => clearTimeout(timer);
    }, [value]);

    return (
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "secondary.secondary",
          borderWidth: "2px",
          borderColor: "secondary.main",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(0.95)",
          },
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            color="text.secondary"
            gap={2}
          >
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <Box position="relative" display="inline-flex">
                <CircularProgress
                  variant="determinate"
                  size={size}
                  thickness={thickness}
                  value={100}
                  color="primary"
                />
                <CircularProgress
                  variant="determinate"
                  size={size}
                  thickness={thickness}
                  value={progress}
                  color="secondary"
                  sx={{
                    position: "absolute", top: 0, left: 0,
                    animation: `${shimmer} 1.5s infinite ease-in-out`,
                  }}
                />
              </Box>
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {icon}
                  <Typography fontSize="12px" textAlign="center">
                    {label}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography>{valueLabel}</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box p="16px">
      <Box display="flex" flexDirection="column" width="90vw">
        <Grid2 container gap={1} justifyContent="center">
          <Typography fontStyle="italic" color="text.secondary">
            {strings.level}:
          </Typography>
          {strings.skillLevel.map((value, index) => (
            <Typography key={index} fontStyle="italic" color="text.secondary">
              "{value}"
            </Typography>
          ))}
        </Grid2>
        <Typography variant="h6" color="text.secondary" mt="32px">
          {strings.tabsTitleSkills}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: "text.secondary" }} />
        <Grid2
          container
          mt="16px"
          justifyContent="center"
          gap={2}
          sx={{ flexGrow: 1 }}
        >
          {SkillLevel(
            <Android fontSize="large" />,
            "Android",
            constants.skillLevelValue.android,
            skillLevelLabel(constants.skillLevelValue.android)
          )}
          {SkillLevel(
            <img
              src={JetpackComposeImg}
              width="24px"
              alt="Jetpack Compose Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "Jetpack Compose",
            constants.skillLevelValue.jetpackCompose,
            skillLevelLabel(constants.skillLevelValue.jetpackCompose)
          )}
          {SkillLevel(
            <img
              src={ReactImg}
              width="32px"
              alt="React Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "ReactJS",
            constants.skillLevelValue.reactJs,
            skillLevelLabel(constants.skillLevelValue.reactJs)
          )}
          {SkillLevel(
            <GitHub fontSize="large" />,
            "GitHub",
            constants.skillLevelValue.gitHub,
            skillLevelLabel(constants.skillLevelValue.gitHub)
          )}
          {SkillLevel(
            <img
              src={FigmaImg}
              width="32px"
              alt="Figma Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "Figma",
            constants.skillLevelValue.figma,
            skillLevelLabel(constants.skillLevelValue.figma)
          )}
          {SkillLevel(
            <img
              src={NodeJsImg}
              width="32px"
              alt="NodeJS Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "NodeJS",
            constants.skillLevelValue.nodeJs,
            skillLevelLabel(constants.skillLevelValue.nodeJs)
          )}
          {SkillLevel(
            <Devices fontSize="large" />,
            <Typography fontSize="10px">Device Repair</Typography>,
            constants.skillLevelValue.deviceRepair,
            skillLevelLabel(constants.skillLevelValue.deviceRepair)
          )}
        </Grid2>
      </Box>
      <Box display="flex" flexDirection="column" width="90vw" mt="32px">
        <Typography variant="h6" color="text.secondary">
          {strings.programmingLanguages}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: "text.secondary" }} />
        <Grid2
          container
          mt="16px"
          justifyContent="center"
          gap={2}
          sx={{ flexGrow: 1 }}
        >
          {SkillLevel(
            <img
              src={KotlinImg}
              width="32px"
              alt="Kotlin Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "Kotlin",
            constants.skillLevelValue.kotlin,
            skillLevelLabel(constants.skillLevelValue.kotlin)
          )}
          {SkillLevel(
            <Javascript fontSize="large" />,
            "JavaScript",
            constants.skillLevelValue.javaScript,
            skillLevelLabel(constants.skillLevelValue.javaScript)
          )}
          {SkillLevel(
            <img
              src={TypeScriptImg}
              width="28px"
              alt="Typescript Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "TypeScript",
            constants.skillLevelValue.typeScript,
            skillLevelLabel(constants.skillLevelValue.typeScript)
          )}
          {SkillLevel(
            <img
              src={JavaImg}
              width="32px"
              alt="Java Icon"
              loading="lazy"
              style={{ filter: "invert(100%)" }}
            />,
            "Java",
            constants.skillLevelValue.java,
            skillLevelLabel(constants.skillLevelValue.java)
          )}
        </Grid2>
      </Box>
      <Box display="flex" flexDirection="column" width="90vw" mt="32px">
        <Typography variant="h6" color="text.secondary">
          {strings.languages}
        </Typography>
        <Divider sx={{ width: "100%", backgroundColor: "text.secondary" }} />
        <Grid2
          container
          mt="16px"
          justifyContent="center"
          gap={2}
          sx={{ flexGrow: 1 }}
        >
          {SkillLevel(
            <Typography>IN</Typography>,
            "Indonesian",
            constants.skillLevelValue.indonesian,
            skillLevelLabel(constants.skillLevelValue.indonesian)
          )}
          {SkillLevel(
            <Typography>EN</Typography>,
            "English",
            constants.skillLevelValue.english,
            skillLevelLabel(constants.skillLevelValue.english)
          )}
          {SkillLevel(
            <Typography>JA</Typography>,
            "Japanese",
            constants.skillLevelValue.japanese,
            skillLevelLabel(constants.skillLevelValue.japanese)
          )}
        </Grid2>
      </Box>
    </Box>
  );
}

export default Skills;
