const utils = {
  calculateAge: (value) => {
    const startDate = new Date(value);
    const currentDate = new Date();

    const differenceInMilliseconds = currentDate - startDate;

    const ageInYears = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25)
    );

    const ageInMonths = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60 * 24 * 365.25)) /
      (1000 * 60 * 60 * 24 * 30.4375)
    );

    const yearsOnly = ageInYears
    const age = ageInYears >= 1 && ageInMonths >= 1 ? `${ageInYears}+` : ageInYears;

    return {
      yearsOnly, age
    }
  },

  formatDate: (value, locale) => {
    const [day, month, year] = value.split('/');
    const date = new Date(`${year}-${month}-${day}`);

    const formattedDate = date.toLocaleDateString(locale, {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });

    return formattedDate;
  }
};

export default utils;
